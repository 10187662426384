import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const API = process.env.NEXT_PUBLIC_API_URL;

export const docsApi = createApi({
    reducerPath: "docsApi",
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers) => {
            return headers;
        }
    }),
    endpoints: (builder) => ({
        generateDoc: builder.mutation({
            query: (newDoc) => ({
                url: API + "/v1/documents",
                method: "POST",
                body: newDoc,
                cache: "no-cache",
                responseHandler: async (response) =>
                    window.open(window.URL.createObjectURL(await response.blob()), "_blank")
            })
        })
    })
});

export const { useGenerateDocMutation } = docsApi;