export const RouterConfig = {
    MAIN: "/",
    CATALOG: "/catalog",
    PRODUCT: "/product",
    CREATE_OFFER: "/post",
    INSURANCE: "/insurance",
    ANALYTICS: "/analytics",
    BLOG: "/blog",
    COMPARE: "/compare",
    FAVORITES: "/saved",
    MY_ADS: "/my-ads",
    MY_ADS_EDIT: "/my-ads/edit",
    HISTORY: "/history",
    JOB: "/job",
    MARKETPLACES: "/marketplaces",
    PROPERTY: "/property",
    PROFILE: "/profile",
    VERIFICATION: "/verification",
    RESTORE_PASSWORD: "/restore-password",
    PRIVACY_POLICY: "/docs/privacy-policy",
    TERMS_OF_USE: "/docs/terms_of_use_LIKVI.pdf",
    GENERATOR_DKP: "/contract-generator"
};