import * as z from "zod";

export const ApiChartResponseSchema = z.object({
    avgPrice: z.number(),
    date: z.string()
});

export const PriceRangeResponseSchema = z
    .object({
        low: z.number(),
        high: z.number()
    })
    .refine((data) => data.low < data.high, {
        message: "low should be less than high",
        path: ["low", "high"]
    })
    .refine((data) => data.low > 0, { message: "low should be greater than 0" })
    .refine((data) => data.high > 0, { message: "high should be greater than 0" });

export const ApiChartResponseArraySchema = z.array(ApiChartResponseSchema).nonempty();

export type ApiChartResponse = z.infer<typeof ApiChartResponseSchema>;
export type ApiChartResponseArray = z.infer<typeof ApiChartResponseArraySchema>;
export type PriceRangeResponse = z.infer<typeof PriceRangeResponseSchema>;