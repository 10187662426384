import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./query";
import { SendCallbackArgs } from "./types";

const API = process.env.NEXT_PUBLIC_API_URL;

export const callbackApi = createApi({
    reducerPath: "callbackApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        sendCallback: builder.mutation({
            query: (data: SendCallbackArgs) => ({
                url: `${API}/v1/feedback`,
                method: "POST",
                body: data
            })
        })
    })
});

export const { useSendCallbackMutation } = callbackApi;