import { CURRENCY } from "@shared/lib/utils/getCurrencySymbol/constants";

export const getCurrencySymbol = (sourceId: number): string => {
    switch (sourceId) {
        case 6:
        case 7:
        case 8:
            return CURRENCY.TENGE;
        case 9:
            return CURRENCY.EURO;
        case 10:
            return CURRENCY.BELARUSIAN_RUBLE;
        default:
            return CURRENCY.RUSSIAN_RUBLE;
    }
};