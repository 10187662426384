import { useRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "@app/store/store";
import {
    getAccessToken,
    getUserData,
    setReduxAccessToken,
    setRouteToOpen,
    setUser
} from "@app/store/authSlice";
import { showEnterForm } from "@app/store/layoutSlice";
import { User } from "@src/interfaces/user.interface";
import { isPrivateRoute } from "./lib";
import { AuthCheckProps } from "./types";

export const AuthCheck = ({ children }: AuthCheckProps) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const user = useSelector((state: AppState) => state.auth.currentUser);
    const pathname = router.pathname;

    useEffect(() => {
        let currentUser: User | null;
        if (typeof window !== "undefined") {
            currentUser = getUserData() as User | null;

            if (currentUser) {
                dispatch(setUser(currentUser));
            }

            const token = getAccessToken();
            dispatch(setReduxAccessToken(token));
        } else {
            currentUser = null;
        }
    }, []);

    useEffect(() => {
        const start = (url: string) => {
            if (isPrivateRoute(url) && !user) {
                dispatch(showEnterForm());
                throw "Abort route change by user's confirmation.";
            }
        };
        router.events.on("routeChangeStart", start);
        return () => {
            router.events.off("routeChangeStart", start);
        };
    }, [user]);

    useEffect(() => {
        const currentUser = getUserData() as User | null;
        if (isPrivateRoute(pathname || "") && !currentUser) {
            if (pathname) {
                dispatch(setRouteToOpen(pathname));
            }
            dispatch(showEnterForm());
        }
    }, []);

    return children;
};