import { createApi, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { User } from "../../interfaces/user.interface";
import { baseQueryWithReauth } from "./query";
import { getAccessToken, setUser, setUserData } from "./authSlice";
import { ProfileResponseScheme } from "../../interfaces/profile.scheme";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showFinishRegistrationForm } from "./layoutSlice";

export interface ProfileState {
    id?: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    isNotificationEnabled: boolean;
}

const API = process.env.NEXT_PUBLIC_API_URL;

export const profileApi = createApi({
    reducerPath: "profileApi",
    tagTypes: ["Profile"],
    baseQuery: fetchBaseQuery({
        baseUrl: `${API}/v1/profile`,
        headers: { "Content-Type": "application/json" }
    }),
    endpoints: (builder) => ({
        getProfile: builder.query<User, any>({
            providesTags: ["Profile"],
            queryFn: async (args, api, extraOptions) => {
                const result: any = await baseQueryWithReauth(
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-Auth-token": args.accessToken
                        },
                        url: `${API}/v1/profile/full`
                    },
                    api,
                    extraOptions
                );

                const status = result.meta.response.status;
                const isSuccess = status === 200;

                const isValid = ProfileResponseScheme.safeParse(result?.data);

                if (!result.data.data.firstName) {
                    api.dispatch(showFinishRegistrationForm());
                }

                api.dispatch(setUser(result.data.data));
                return isSuccess && isValid
                    ? { data: convertProfileApiDataToUser(result.data.data) as User }
                    : {
                          error: isValid
                              ? (result.error as FetchBaseQueryError)
                              : { data: "Not valid server answer (Profile Scheme) ", status: 400 }
                      };
            },
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const result = await queryFulfilled;
                if (result.data) {
                    if (typeof window !== "undefined") {
                        setUserData(result.data);
                        dispatch(setUser(result.data));
                    }
                }
            }
        }),

        updateProfile: builder.mutation<User, any>({
            query: (payload) => {
                let TOKEN = "";
                if (typeof window !== "undefined") {
                    TOKEN = getAccessToken();
                }
                return {
                    url: "",
                    method: "PATCH",
                    body: {
                        ...payload,
                        middleName: "",
                        lastName: payload.lastName || "",
                        isNotificationEnabled: payload.isNotificationEnabled
                    },
                    headers: {
                        "Content-Type": "application/json",
                        "X-Auth-token": TOKEN
                    }
                };
            },
            invalidatesTags: ["Profile"]
        })
    })
});

export const changePassword = createAsyncThunk<any, any, { rejectValue: { message: string } }>(
    "profileApi/changePassword",
    async (payload: string, thunkAPI) => {
        const TOKEN = getAccessToken();
        const URL = `${API}/v1/users/password_change`;
        const result: any = await baseQueryWithReauth(
            {
                headers: {
                    "Content-Type": "application/json",
                    "X-Auth-token": TOKEN
                },
                url: URL,
                method: "POST",
                body: {
                    password: payload
                }
            },
            {
                ...thunkAPI,
                endpoint: URL,
                type: "mutation"
            },
            {}
        );

        const status = result.meta.response.status;
        const isSuccess = status === 200;

        if (!isSuccess) {
            const errTexts = Object.values(result?.error?.data?.data).join(", ");
            return thunkAPI.rejectWithValue({
                message: errTexts
            });
        }

        return result.data;
    }
);

export const convertProfileApiDataToUser = (data: any): User => {
    return {
        id: data.userUuid,
        name: data.firstName,
        lastName: data.lastName,
        email: data.user.email,
        phone: data.user.phone,
        isEmailVerified: data.user.isEmailVerified,
        isPhoneVerified: data.user.isPhoneVerified,
        isNotificationEnabled: data.isNotificationEnabled
    };
};

export const { useGetProfileQuery, useUpdateProfileMutation } = profileApi;