export const API_ENDPOINTS = {
    BRANDS: "/brands/_search?size=500",
    MODEL_SEARCH: "/models/_search",
    GENERATIONS_SEARCH: "/generations/_search",
    CITY_SEARCH: "/cities/_search?size=999&filter_path=hits.hits.**.city",
    BODY_TYPES: (queryParams: string) => `/v1/options/body_types${queryParams}`,
    TRANSMISSION_TYPES: (queryParams: string) => `/v1/options/transmission_types${queryParams}`,
    ENGINE_TYPES: (queryParams: string) => `/v1/options/engine_types${queryParams}`,
    DRIVE_TYPES: (queryParams: string) => `/v1/options/drive_types${queryParams}`,
    DISPLACEMENTS: (queryParams: string) => `/v1/options/displacements${queryParams}`,
    WHEEL_TYPES: (queryParams: string) => `/v1/options/wheel_types${queryParams}`,
    SELLER_TYPES_SEARCH: "/seller_types/_search?size=500"
};