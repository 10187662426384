import { CURRENT_YEAR, START_YEAR_OPTIONS } from "@shared/config/Date";
import { YearOption } from "@shared/lib/utils/generateYearsOptions/types";

export function generateYearsOptions(
    startYear: number | null,
    endYear: number | null
): YearOption[] {
    const years: YearOption[] = [];
    if (
        startYear !== null &&
        endYear !== null &&
        startYear >= START_YEAR_OPTIONS &&
        endYear >= START_YEAR_OPTIONS
    ) {
        for (let y = endYear; y >= startYear; y--) {
            years.push({
                label: y.toString(),
                value: y.toString()
            });
        }
    }
    if (years.length === 0) {
        for (let y = CURRENT_YEAR; y >= START_YEAR_OPTIONS; y--) {
            years.push({
                label: y.toString(),
                value: y.toString()
            });
        }
    }
    return years;
}