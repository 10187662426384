import { DateTime } from "luxon";
import { LabelCases } from "@shared/lib/utils/toRelativeTime/types";

const createLabel = (number: number, labels: LabelCases) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return labels[
        number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
    ];
};

export const toRelativeTime = (isoDate: string) => {
    const date1 = DateTime.fromISO(isoDate, { zone: "UTC+3" }).setLocale("ru");
    const date2 = DateTime.now().setLocale("ru");

    const diff = date2.diff(date1, ["years", "months", "days", "hours", "minutes", "seconds"]);
    const updated = diff.toObject();

    let updatedStr = "";

    const prepareVal = (val: number | undefined): number => (val ? Math.abs(Math.round(val)) : 0);

    const years = prepareVal(updated.years);
    const months = prepareVal(updated.months);
    const days = prepareVal(updated.days);
    const hours = prepareVal(updated.hours);
    const minutes = prepareVal(updated.minutes);
    const seconds = prepareVal(updated.seconds);

    if (years > 0 || months > 0 || days > 1) {
        if (date1.year === date2.year) {
            updatedStr = `${date1.toFormat("d MMMM")}`;
        } else {
            updatedStr = `${date1.toFormat("d MMMM yyyy")}`;
        }
    } else if (days === 1) {
        updatedStr = `${date1.toFormat("d MMMM")}`;
    } else if (hours > 0) {
        updatedStr = `${hours} ${createLabel(hours, ["час", "часа", "часов"])} назад`;
    } else if (minutes > 0) {
        updatedStr = `${minutes} ${createLabel(minutes, ["минута", "минуты", "минут"])} назад`;
    } else if (seconds > 0) {
        updatedStr = `несколько секунд назад`;
    } else {
        updatedStr = "только что";
    }

    return updatedStr;
};