import { createAction, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from "@reduxjs/toolkit";
import { AppState } from "./store";
import { HYDRATE } from "next-redux-wrapper";
import { PostFormState, postEditFormsReducers } from "./postFormSlice";

const hydrate = createAction<AppState>(HYDRATE);

export interface AnalyticsFormState extends PostFormState {
    selectedEngineDisplacement?: string;
    selectedPts?: string;
    selectedPtsValid: boolean;
    selectedEngineDisplacementValid: boolean;
    onwersQnt?: number;
    onwersQntValid: boolean;
}

const initialState: AnalyticsFormState = {
    coordinates: [],
    selectedDriveUpdated: false,
    selectedDriveValid: false,
    mileageUpdated: false,
    mileageValid: false,
    autoFilled: false,
    characteristicsFilled: false,
    photoFilled: false,
    descriptionFilled: false,
    contactsFilled: false,
    placeFilled: false,
    priceFilled: false,
    isFilled: false,
    selectedPics: [],
    selectedSellerType: "individual",
    selectedYearUpdated: false,
    selectedYearValid: false,

    selectedBodyTypeUpdated: false,
    selectedBodyTypeValid: false,

    selectedPtsValid: false,
    selectedEngineDisplacementValid: false,
    selectedTransmissionValid: false,
    horsePowerValid: false,
    selectedEngineValid: false,
    onwersQntValid: false
};

export const analyticsFormsReducers: ValidateSliceCaseReducers<
    AnalyticsFormState,
    SliceCaseReducers<AnalyticsFormState>
> = {
    ...postEditFormsReducers,
    setSelectedPts: (state, action: PayloadAction<string>) => {
        state.selectedPts = action.payload;
        if (action.payload) {
            state.selectedPtsValid = true;
        }
    },

    setSelectedEngineDisplacement: (state, action: PayloadAction<string>) => {
        state.selectedEngineDisplacement = action.payload;

        if (action.payload) {
            state.selectedEngineDisplacementValid = true;
        }
    },

    setOwnersQnt: (state, action: PayloadAction<number>) => {
        state.onwersQnt = action.payload;

        if (action.payload) {
            state.onwersQntValid = true;
        }
    }
};
export const analyticsFormSlice = createSlice({
    name: "analyticsForm",
    initialState,
    reducers: analyticsFormsReducers,

    extraReducers: (builder) => {
        builder.addCase(hydrate, (state, action) => {
            return {
                ...state,
                ...action.payload.postForm
            };
        });
    }
});

export const {
    updateStatus,
    setBrand,
    setModel,
    setGeneration,
    setSelectedEngine,
    setSelectedDrive,
    setBodyType,
    setYear,
    setSelectedColor,
    setSelectedDescription,
    setSelectedPrice,
    setSelectedCity,
    setSelectedCountry,
    setSelectedPhone,
    setSelectedName,
    setSelectedSellerType,
    setSelectedBodyType,
    setSelectedMileage,
    setSelectedStreet,
    setSelectedPics,
    setSelectedOwnersQnt,
    setSelectedTransmission,
    setSelectedWheel,
    loadDefaultValues,
    reset,
    setHorsePower,
    setVinNumber,
    setCoordinates,
    setSelectedPts,
    setSelectedEngineDisplacement,
    setOwnersQnt
} = analyticsFormSlice.actions;

export const actions = analyticsFormSlice.actions;

export const selectFilledStatus = (state: AppState) => state.analyticsForm;

export default analyticsFormSlice.reducer;