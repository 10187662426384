import { z } from "zod";

export const ProfileResponseScheme = z.object({
    code: z.number(),
    message: z.string(),
    data: z.object({
        firstName: z.string(),
        lastName: z.string(),
        middleName: z.string(),
        user: z.object({
            id: z.string(),
            email: z.string(),
            phone: z.string(),
            isEmailVerified: z.boolean(),
            isPhoneVerified: z.boolean()
        })
    })
});

export type ProfileResponse = z.infer<typeof ProfileResponseScheme>;